<!-- Selection control to change who is logged in. -->
<template>
  <div class="d-flex justify-content-start align-items-baseline">
    <div class="w-100">
      <material-api-dropdown
        css-class      = "display-block w-450"
        input-class    = "devtools-input-color-white"
        dropdown-class = "color-dark-gray"
        api-url        = "/api/autocomplete/impersonate"
        v-model        = "internalUsername"
      ></material-api-dropdown>
    </div>

    <button
      class  = "ms-3 btn background-color-nav-blue border-color-nav-blue text-white"
      @click = "impersonate"
    >Impersonate</button>
  </div>
</template>

<script>
  import MaterialApiDropdown from './material_api_dropdown.vue'
  import {put}               from '../lib/async.js'

  export default {
    components: {
      MaterialApiDropdown
    },

    props: {
      // User name displayed in impersonate section.
      username: { default: null },
    },

    data() {
      return {
        // Internal mutable representation of the username prop.
        internalUsername: this.username
      }
    },

    methods: {
      // Internal: Log in as another user.
      impersonate() {
        put('/api/users/impersonate', { username: this.internalUsername })
          .then((results) => {
            document.location.reload()
          })
      },
    }
  }
</script>
