<!--
Public: Create a tile to link to a post. The tile then gets displayed on a collection page or a front page

Properties:
  subject -   Subject of post
  permalink - Local URL of post
  imageUrl -  URL to access tile image
  imageName - Name of tile image
-->

<template>
  <div>
    <a :href="permalink"
       class="position-relative underline-hover-conditional-region d-block" aria-label="">
      <div class="post-grid-card-hero-img-wrapper ratio ratio-16x9 h-100 position-relative d-flex align-items-center justify-content-center border-top-left-radius-20 border-top-right-radius-20">
        <img :src="imageUrl"
             :alt="imageName"
             :title="imageName"
             class ="post-grid-card-hero-img w-100 d-block"
             v-cloak/>
      </div><!-- .post-grid-card-hero-img-wrapper -->

      <div class="gray_card_area_sizer pt-4 pb-1 px-4 position-relative background-color-lightest-gray border-bottom-left-radius-20 border-bottom-right-radius-20 d-flex justify-content-between flex-column">
        <div class="d-flex">
          <div class="d-flex align-items-center color-nav-blue fw-bold text-overflow-elipsis mb-3 ui-fs-7 post-grid-tile-title-sizer">
            <div v-cloak class="underline-hover-select text-overflow-elipsis">
              {{ subject }}
            </div>
          </div>
        </div>

        <div class="mb-3">
          <div v-cloak class="color-medium-gray ui-fs-7 mb-2 mb-lg-0">
            {{ date }} &nbsp;<!-- guard against empty date breaking height -->
          </div>
        </div><!-- .mb-3 -->
      </div><!-- .gray_card_area_sizer -->
    </a>
  </div>
</template>

<script>
  export default {
    props: {
      // Subject of post
      subject:   { default: '' },
      // Date of post
      date:      { default: '' },
      // Local URL of post
      permalink: { default: '' },
      // URL to access tile image
      imageUrl:  { default: '' },
      // Name of tile image
      imageName: { default: '' }
    }
  }
</script>
