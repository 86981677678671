<!--
Similar to the <material-dropdown> component, but the data in the
dropdown is driven by an api endpoint.
-->
<template>
  <div class="material-api-dropdown">
    <material-dropdown
      ref                       = "dropdown"
      css-class                 = "cssClass"
      auto-select               = "false"
      hide-decorations          = "true"
      show-decorations          = "true"
      show-placeholder-on-focus = "false"
      searchable                = "true"
      :chevron-color            = "chevronColor"
      :small-chevron            = "internalSmallChevron"
      :blue-underline           = "blueUnderline"
      :dropdown-class           = "dropdownClass"
      :external-items           = "items"
      :id                       = "id"
      :input-class              = "inputClass"
      :name                     = "name"
      :placeholder              = "placeholder"
      :value                    = "internalValue"
      @change                   = "onChange"
      @keyup                    = "onKeyup"
      @blur                     = "onBlur"
      @click                    = "onClick"
    ></material-dropdown>
  </div>
</template>

<script>
  import MaterialDropdown from './material_dropdown.vue'
  import {get}            from '../lib/async.js'
  import {toBoolean}      from '../lib/utils.js'

  export default {
    components: {
      MaterialDropdown
    },

    props: {
      // Endpoint where the dropdown data is retrieved from.
      apiUrl:        { default: null },
      // Alternative chevron color passed to the material dropdown
      chevronColor:  { default: 'medium-blue' },
      // Show animated blue underline
      blueUnderline: { default: true },
      // CSS applied to the material dropdown component.
      cssClass:      { default: '' },
      // Classes applied to the dropdown element.
      dropdownClass: { default: '' },
      // Passed along to the internal input attribute for posting.
      id:            { default: '' },
      // Classes applied to the input element.
      inputClass:    { default: '' },
      // Passed along to the internal input attribute for posting.
      name:          { default: '' },
      // Text to display prior to having a value
      placeholder:   { default: '' },
      // Display a smaller chevron
      smallChevron:  { default: false },
      // Input value.
      modelValue:    { default: null }
    },

    data() {
      return {
        // Internal: List of items retrieved from the API endpoint.
        items: []
      }
    },

    computed: {
      // Internal: Coercing smallChevron property to boolean
      internalSmallChevron(){
        return toBoolean(this.smallChevron)
      }
    },

    methods: {
      // Internal: Call the API to get results.
      getItems() {
        get(this.apiUrl, { query: this.$refs.dropdown.internalText })
          .then((response) => {
            this.items = response.items.map((item) => {
              return { text: item.text, value: item.value }
            })
          })
      },

      // Internal: Blur event handler
      onBlur(value) {
        this.$emit('blur', value)
        this.$emit('update:modelValue', value)
      },

      // Internal: Click event handler
      onClick() {
        this.$emit('click')
      },

      // Intenal: Change event handler.
      onChange(item) {
        let value = this.$refs.dropdown.internalValue
        this.$emit('input', value)
        this.$emit('update:modelValue', value)
      },

      // Internal: Key up event handler.
      onKeyup(event) {
        this.getItems()
      }
    },

    mounted() {
      this.items = [ { text: this.value, value: this.value } ]
    }
  }
</script>

<style lang="scss">
  @import "../../assets/stylesheets/colors.scss";
</style>
