<!--
Display a closable message - generally at the top of the page.
-->

<template>
  <div v-if   = "open"
       :class = "`py-3 alert mb-4 d-flex justify-content-between align-items-center fade-in ${alertCss}`"
       role   = "alert">
    <div class="d-flex justify-content-start align-items-center fs-6 fw-semibold">
      <div v-if="isError"
          class="fw-bold me-2 d-flex justify-content-start align-items-center">
        <i class="bi bi-exclamation-triangle me-3 fs-2"></i>
      </div>
      <div v-if="isSuccess"
           class="fw-bold me-2 d-flex justify-content-start align-items-center">
        <i class="bi bi-check-circle me-3 fs-2"></i>
      </div>
      <div>
        <slot>
        </slot>
      </div>
    </div>
    <a href           = ""
       type           = "button"
       class          = "close text-decoration-none x-hover fs-1"
       data-dismiss   = "alert"
       aria-label     = "Close"
       @click.prevent = "onCloseClick">
      <span aria-hidden="true">&times;</span>
    </a>
  </div>
</template>

<script>
  import {blank, present} from '../lib/utils.js'

  export default {
    props: {
      // Type of message: danger, error, info, success
      type: { default: '' },
    },

    data() {
      return {
        // Internal: Show this component if true.
        open: true
      }
    },

    computed: {
      // Internal: CSS styling specific to the alert type.
      alertCss() {
        if (blank(this.type)) return ''

        return `alert-${this.type.toLowerCase()}`
      },

      // Internal: Whether or not the alert is a red error alert
      isError() {
        return this.alertCss === 'alert-danger'
      },

      // Internal: Whether or not the alert is a green success alert
      isSuccess() {
        return this.alertCss === 'alert-success'
      }
    },

    methods: {
      // Close button click event handler.
      onCloseClick() {
        this.open = false
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/stylesheets/colors.scss";

  .alert {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.12);
    text-shadow: 2px 2px 4px 3px rgba(0, 0, 0, .2);
    border-style: solid;
    border-width: 2px;
    border-color: rgba(14,14,14,.2);
    z-index:      10000;
  }

  .alert-danger {
    background-color: $muted-red;
    color:            $muted-red-border !important;
    border-color:     $muted-red-border !important;

    > .x-hover {
      color:          $muted-red-border !important;
    }
  }

  .alert-danger a {
    color:  $medium-gray !important;
    text-decoration: underline;
  }

  .button-danger {
    @extend .button-danger;
    color:  $medium-gray !important;
  }

  .alert-success {
    background-color: $success-green;
    color:            $success-green-border !important;
    border-color:     $success-green-border !important;

    > .x-hover {
      color:          $success-green-border !important;
    }
  }

  .x-hover {
    transition: all 0.2s ease;

    &:hover {
      filter: contrast(.5);
    }
  }
</style>
